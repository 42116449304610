import * as React from "react"
import { Link } from "gatsby"
import Spacer from "../components/spacer"
import { useStaticQuery, graphql } from "gatsby"

const Footer = () => {
  var data  = useStaticQuery(
    graphql`
      query Footer {
        footer:datoCmsWaltProperty {
          instagram
          facebook
          linkedin
          location
        }
      }
    `
  )
  data = data.footer;
  return (
  <footer className='p40 bg-green white flex m-wrap'>
    <div className='w-50 m-100 flex flex-wrap br1 m-b0'>
      <div className='w-100'>
        <Link to='/' className='link'>
          <div className='logo--main alt'/>
        </Link>
        <Spacer />
        <Spacer />
      </div>
      <div className='w-100 beige m-hide'>
        <Link className='link' to='/policy'>Privacy Policy</Link> <span className='ml10 mr10'>|</span> Walt Property Group © {new Date().getFullYear()}
      </div>
    </div>
    <div className='w-50 m-100 flex m-wrap'>
      <div className='pl40 m-100 flex m-p0'>
        <div className='w-50 flex flex-wrap'>
          <div className='w-100'>
            <Link to='/#about' className='link beige h2'>About Us</Link><br/>
            <Link to='/contact' className='link beige h2'>Contact</Link>
          </div>
          <div className='mta beige m-hide' dangerouslySetInnerHTML={{ __html: data.location }}  />
        </div>
        <div className='w-50'>
          <a href={data.facebook} className='link beige h2'>Facebook</a><br/>
          <a href={data.instagram} className='link beige h2'>Instagram</a><br/>
          <a href={data.linkedin} className='link beige h2'>Linkedin</a>
        </div>
      </div>
      <div className='m-100 m-show m-small m-mt40 beige'>
        <Link className='link' to='/policy'>Privacy Policy</Link> <span className='ml10 mr10'>|</span> Walt Property Group © {new Date().getFullYear()}
      </div>
    </div>
  </footer>
  )
}

export default Footer
