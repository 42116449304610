import * as React from "react" 
import Seo from "../components/seo"
import Layout from "../components/layout"
import Title from "../components/title"
import Spacer from "../components/spacer"
import { graphql } from "gatsby" 

const PolicyPage = ({data}) => {
  return (
  <>
    <Seo title="Privacy Policy" />
    <Layout>
      <Spacer />
      <Title title='Privacy Policy' />
      <div className='content m0 medium p40'>
        {data.policy.privacyPolicy}
      </div>
      <Spacer />
    </Layout>
  </>
  )
}

export default PolicyPage

export const query = graphql`
  query Privacy {
    policy:datoCmsPrivacyPolicy {
      privacyPolicy
    }
  }
`