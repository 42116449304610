import * as React from "react"
import PropTypes from "prop-types"
import scrollTo from "./scrollTo"
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
  <header className='masthead'>
    <div className='p40'>
      <div className='flex w-100'>
        <Link to="/"><div className='logo--main'/></Link>
        <div className='menu mla medium flex'>
          <p className='link m-hide' onClick={() => scrollTo('#about')} role='presentation'>About Us</p>
          <Link className='link button ml40' to='/contact'>Contact Us</Link>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
